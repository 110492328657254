var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");
'use strict';var u4,w4,x4,C4,Uqa,I4;u4=new $CLJS.N("metabase.lib.types.constants","primary-key","metabase.lib.types.constants/primary-key",1371730630);$CLJS.v4=new $CLJS.N(null,"exclude","exclude",-1230250334);w4=new $CLJS.N("metabase.lib.types.constants","unknown","metabase.lib.types.constants/unknown",-886227696);x4=new $CLJS.N("metabase.lib.types.constants","string-like","metabase.lib.types.constants/string-like",776315654);
$CLJS.y4=new $CLJS.N("metabase.lib.types.constants","string","metabase.lib.types.constants/string",-2076057579);$CLJS.z4=new $CLJS.N("metabase.lib.types.constants","string_like","metabase.lib.types.constants/string_like",-1907279613);$CLJS.A4=new $CLJS.N("metabase.lib.types.constants","number","metabase.lib.types.constants/number",1385378285);$CLJS.B4=new $CLJS.N("metabase.lib.types.constants","location","metabase.lib.types.constants/location",1597944369);
C4=new $CLJS.N("metabase.lib.types.constants","foreign-key","metabase.lib.types.constants/foreign-key",7703392);$CLJS.D4=new $CLJS.N("metabase.lib.types.constants","category","metabase.lib.types.constants/category",-644284871);$CLJS.E4=new $CLJS.N("metabase.lib.types.constants","foreign_key","metabase.lib.types.constants/foreign_key",-1061052182);$CLJS.F4=new $CLJS.N("metabase.lib.types.constants","boolean","metabase.lib.types.constants/boolean",-2002690319);
$CLJS.G4=new $CLJS.N(null,"include","include",153360230);$CLJS.H4=new $CLJS.N("metabase.lib.types.constants","primary_key","metabase.lib.types.constants/primary_key",1915474107);Uqa=new $CLJS.N("metabase.lib.types.constants","dimension","metabase.lib.types.constants/dimension",728254877);I4=new $CLJS.N("metabase.lib.types.constants","entity","metabase.lib.types.constants/entity",-64775675);$CLJS.J4=new $CLJS.N("metabase.lib.types.constants","coordinate","metabase.lib.types.constants/coordinate",-565327840);
$CLJS.K4=new $CLJS.N("metabase.lib.types.constants","temporal","metabase.lib.types.constants/temporal",896988859);$CLJS.L4=new $CLJS.N("metabase.lib.types.constants","integer","metabase.lib.types.constants/integer",1592888555);var M4=$CLJS.Sb(function(a,b){var c=$CLJS.ui(b);a[c]=b;return a},{},$CLJS.en.g($CLJS.sg($CLJS.gn,$CLJS.G([new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ll,$CLJS.al,$CLJS.Qj],null)]))));$CLJS.Ra("metabase.lib.types.constants.name__GT_type",M4);$CLJS.Ra("metabase.lib.types.constants.key_number",$CLJS.A4);$CLJS.Ra("metabase.lib.types.constants.key_string",$CLJS.y4);$CLJS.Ra("metabase.lib.types.constants.key_string_like",x4);$CLJS.Ra("metabase.lib.types.constants.key_boolean",$CLJS.F4);
$CLJS.Ra("metabase.lib.types.constants.key_temporal",$CLJS.K4);$CLJS.Ra("metabase.lib.types.constants.key_location",$CLJS.B4);$CLJS.Ra("metabase.lib.types.constants.key_coordinate",$CLJS.J4);$CLJS.Ra("metabase.lib.types.constants.key_foreign_KEY",C4);$CLJS.Ra("metabase.lib.types.constants.key_primary_KEY",u4);$CLJS.Ra("metabase.lib.types.constants.key_summable",$CLJS.Sl);$CLJS.Ra("metabase.lib.types.constants.key_scope",$CLJS.Dk);$CLJS.Ra("metabase.lib.types.constants.key_category",$CLJS.D4);
$CLJS.Ra("metabase.lib.types.constants.key_unknown",w4);
$CLJS.N4=$CLJS.Sh([$CLJS.J4,$CLJS.z4,I4,$CLJS.E4,$CLJS.L4,$CLJS.Dk,$CLJS.A4,$CLJS.B4,$CLJS.F4,$CLJS.y4,$CLJS.Sl,$CLJS.D4,$CLJS.H4,$CLJS.K4,Uqa],[new $CLJS.k(null,1,[$CLJS.Hl,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.jl],null)],null),new $CLJS.k(null,1,[$CLJS.jk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Xk],null)],null),new $CLJS.k(null,1,[$CLJS.Hl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kj,$CLJS.Sj,$CLJS.Sk],null)],null),new $CLJS.k(null,1,[$CLJS.Hl,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Kj],null)],null),new $CLJS.k(null,
1,[$CLJS.jk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.gl],null)],null),new $CLJS.k(null,2,[$CLJS.G4,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.A4,$CLJS.K4,$CLJS.D4,I4,$CLJS.y4],null),$CLJS.v4,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.B4],null)],null),new $CLJS.k(null,2,[$CLJS.jk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Bl],null),$CLJS.Hl,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Bl],null)],null),new $CLJS.k(null,1,[$CLJS.Hl,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.qk],null)],null),new $CLJS.k(null,1,[$CLJS.jk,new $CLJS.S(null,1,5,
$CLJS.T,[$CLJS.xl],null)],null),new $CLJS.k(null,2,[$CLJS.jk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Pl],null),$CLJS.Hl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Pl,$CLJS.El],null)],null),new $CLJS.k(null,2,[$CLJS.G4,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.A4],null),$CLJS.v4,new $CLJS.S(null,3,5,$CLJS.T,[I4,$CLJS.B4,$CLJS.K4],null)],null),new $CLJS.k(null,3,[$CLJS.jk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.xl],null),$CLJS.Hl,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.El],null),$CLJS.G4,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.B4],
null)],null),new $CLJS.k(null,1,[$CLJS.Hl,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Sj],null)],null),new $CLJS.k(null,2,[$CLJS.jk,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Il],null),$CLJS.Hl,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Il],null)],null),new $CLJS.k(null,1,[$CLJS.G4,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.K4,$CLJS.D4,I4],null)],null)]);
module.exports={key_scope:$CLJS.Dk,key_summable:$CLJS.Sl,key_location:$CLJS.B4,key_coordinate:$CLJS.J4,key_boolean:$CLJS.F4,key_temporal:$CLJS.K4,key_category:$CLJS.D4,key_string:$CLJS.y4,key_foreign_KEY:C4,key_primary_KEY:u4,key_string_like:x4,key_unknown:w4,key_number:$CLJS.A4,name__GT_type:M4};