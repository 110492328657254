var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var Bla,Cla,Dla,Ela,Fla,Gla,Hla,rW,Ila,Jla,Kla,Lla,Mla,Nla,Ola,Pla,tW,Qla;$CLJS.lW=new $CLJS.N(null,"supported-field","supported-field",-2061545519);Bla=new $CLJS.N("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.mW=new $CLJS.N("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Cla=new $CLJS.N("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Dla=new $CLJS.N("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.nW=new $CLJS.N("mbql.aggregation","operator","mbql.aggregation/operator",-1481602310);$CLJS.oW=new $CLJS.N(null,"requires-column?","requires-column?",1623131448);$CLJS.pW=new $CLJS.N(null,"column-name","column-name",551523580);Ela=new $CLJS.N("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.qW=new $CLJS.N(null,"display-info","display-info",-816930907);
Fla=new $CLJS.N("mbql.clause","max","mbql.clause/max",1615385533);Gla=new $CLJS.N("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);Hla=new $CLJS.N("mbql.clause","median","mbql.clause/median",-1026386338);rW=new $CLJS.N("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);Ila=new $CLJS.N("mbql.clause","min","mbql.clause/min",2074448531);Jla=new $CLJS.N("mbql.clause","percentile","mbql.clause/percentile",2064253651);
Kla=new $CLJS.N("mbql.clause","stddev","mbql.clause/stddev",659381076);Lla=new $CLJS.N("mbql.clause","var","mbql.clause/var",809434920);Mla=new $CLJS.N("mbql.clause","share","mbql.clause/share",-37921592);Nla=new $CLJS.N("mbql.clause","count","mbql.clause/count",-454225910);Ola=new $CLJS.N("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.sW=new $CLJS.N(null,"driver-feature","driver-feature",1905324248);Pla=new $CLJS.N("mbql.clause","avg","mbql.clause/avg",1826863173);
tW=new $CLJS.N("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);Qla=new $CLJS.N("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.BV($CLJS.mD,$CLJS.G([$CLJS.yu,$CLJS.gl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BG,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.ML],null)],null)],null)],null)]));$CLJS.BV($CLJS.NJ,$CLJS.G([$CLJS.yu,$CLJS.gl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BG,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.ML],null)],null)],null)],null)]));
$CLJS.zV($CLJS.DJ,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));$CLJS.zV($CLJS.NI,$CLJS.G([$CLJS.yu,$CLJS.gl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.ML],null)],null)]));$CLJS.zV($CLJS.fK,$CLJS.G([$CLJS.yu,$CLJS.gl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.LL],null)],null)]));
$CLJS.zV($CLJS.Vl,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null)],null)]));$CLJS.oV($CLJS.Vl,$CLJS.QL);$CLJS.zV($CLJS.vK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));$CLJS.oV($CLJS.vK,$CLJS.QL);$CLJS.zV($CLJS.ok,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RL],null)],null)]));$CLJS.oV($CLJS.ok,$CLJS.QL);
$CLJS.kL(rW,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,new $CLJS.k(null,1,[$CLJS.ev,"valid percentile"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ev,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.zV($CLJS.nJ,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,rW],null)]));$CLJS.oV($CLJS.nJ,$CLJS.QL);
$CLJS.zV($CLJS.ZJ,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.LL],null)],null)]));$CLJS.zV($CLJS.AK,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));$CLJS.zV($CLJS.tK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));
$CLJS.zV($CLJS.RK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));$CLJS.oV($CLJS.tK,$CLJS.QL);$CLJS.oV($CLJS.RK,$CLJS.QL);$CLJS.zV($CLJS.OK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.LL],null)],null)]));$CLJS.oV($CLJS.OK,$CLJS.QL);
$CLJS.zV($CLJS.RI,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));$CLJS.kL(tW,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.zk,Pla,Nla,Gla,Cla,Bla,Fla,Hla,Ila,Jla,Mla,Kla,Qla,Ola,Dla,Lla,$CLJS.Lb],null));$CLJS.kL($CLJS.mW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kl,new $CLJS.k(null,1,[$CLJS.ok,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,tW],null)],null));
$CLJS.uW=new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.k(null,4,[$CLJS.DV,$CLJS.mD,$CLJS.oW,!1,$CLJS.sW,$CLJS.gI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Count of rows"),$CLJS.pW,$CLJS.WH("Count"),$CLJS.XG,$CLJS.WH("Total number of rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.DV,$CLJS.tK,$CLJS.lW,$CLJS.Sl,$CLJS.oW,!0,$CLJS.sW,$CLJS.gI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Sum of ..."),$CLJS.pW,$CLJS.WH("Sum"),$CLJS.XG,$CLJS.WH("Sum of all the values of a column.")],
null)}],null),new $CLJS.k(null,5,[$CLJS.DV,$CLJS.DJ,$CLJS.lW,$CLJS.Sl,$CLJS.oW,!0,$CLJS.sW,$CLJS.gI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Average of ..."),$CLJS.pW,$CLJS.WH("Average"),$CLJS.XG,$CLJS.WH("Average of all the values of a column")],null)}],null),new $CLJS.k(null,5,[$CLJS.DV,$CLJS.vK,$CLJS.lW,$CLJS.Sl,$CLJS.oW,!0,$CLJS.sW,$CLJS.FJ,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Median of ..."),$CLJS.pW,$CLJS.WH("Median"),$CLJS.XG,$CLJS.WH("Median of all the values of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.DV,$CLJS.NI,$CLJS.lW,$CLJS.Wt,$CLJS.oW,!0,$CLJS.sW,$CLJS.gI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Number of distinct values of ..."),$CLJS.pW,$CLJS.WH("Distinct values"),$CLJS.XG,$CLJS.WH("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.DV,$CLJS.RK,$CLJS.lW,$CLJS.Sl,$CLJS.oW,!0,$CLJS.sW,$CLJS.gI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Cumulative sum of ..."),
$CLJS.pW,$CLJS.WH("Sum"),$CLJS.XG,$CLJS.WH("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.k(null,4,[$CLJS.DV,$CLJS.NJ,$CLJS.oW,!1,$CLJS.sW,$CLJS.gI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Cumulative count of rows"),$CLJS.pW,$CLJS.WH("Count"),$CLJS.XG,$CLJS.WH("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.k(null,5,[$CLJS.DV,$CLJS.AK,$CLJS.lW,$CLJS.Sl,$CLJS.oW,
!0,$CLJS.sW,$CLJS.lK,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Standard deviation of ..."),$CLJS.pW,$CLJS.WH("SD"),$CLJS.XG,$CLJS.WH("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.DV,$CLJS.ok,$CLJS.lW,$CLJS.Dk,$CLJS.oW,!0,$CLJS.sW,$CLJS.gI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Minimum of ..."),$CLJS.pW,$CLJS.WH("Min"),$CLJS.XG,$CLJS.WH("Minimum value of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.DV,$CLJS.Vl,$CLJS.lW,$CLJS.Dk,$CLJS.oW,!0,$CLJS.sW,$CLJS.gI,$CLJS.qW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Maximum of ..."),$CLJS.pW,$CLJS.WH("Max"),$CLJS.XG,$CLJS.WH("Maximum value of a column")],null)}],null)],null);
$CLJS.kL(Ela,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.nW],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.DV,$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Ht],null),$CLJS.rg.g($CLJS.DV),$CLJS.uW)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lW,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.Ck],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oW,$CLJS.ik],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.sW,$CLJS.Ck],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qW,$CLJS.de],null)],null));