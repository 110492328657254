var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var CL,HL,wga,xga,yga,zga,Aga,Bga;CL=function(a){return $CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N};$CLJS.EL=function(a){var b=function(){var c=CL(a);return c?(c=$CLJS.oe($CLJS.Zd(a)))?(c=$CLJS.jk.g($CLJS.Zd(a)),$CLJS.m(c)?c:$CLJS.EG.g($CLJS.Zd(a))):c:c}();return $CLJS.m(b)?b:$CLJS.DL.g(a)};
HL=function(a,b){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,2,[$CLJS.ev,"valid MBQL clause",$CLJS.gv,function(c){c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.ol);return["invalid MBQL clause: ",$CLJS.aj.l($CLJS.G([c]))].join("")}],null),$CLJS.$f(CL)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.FL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ev,b],null),function(c){c=$CLJS.EL(c);
return GL(c,a)}],null)],null)};wga=new $CLJS.N("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);xga=new $CLJS.N("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);yga=new $CLJS.N("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.IL=new $CLJS.N(null,"expr","expr",745722291);
$CLJS.JL=new $CLJS.N("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.KL=new $CLJS.N("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.LL=new $CLJS.N("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);$CLJS.ML=new $CLJS.N("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);
$CLJS.NL=new $CLJS.N("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.OL=new $CLJS.N("lib","expression-name","lib/expression-name",-1799326590);zga=new $CLJS.N("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.PL=new $CLJS.N("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);
$CLJS.QL=new $CLJS.N("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.RL=new $CLJS.N("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Aga=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);Bga=new $CLJS.N("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);
$CLJS.FL=new $CLJS.N("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.SL=new $CLJS.N("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.TL=new $CLJS.N("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.UL=new $CLJS.N("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.DL=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Ok,$CLJS.EH],null),$CLJS.Ok,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.schema.expression","type-of-method"),function(f){var g=$CLJS.DH(f);return $CLJS.F.h(g,$CLJS.DG)?$CLJS.Ob(f):g},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.kL(xga,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.NL],null),$CLJS.AL],null));$CLJS.DL.o(null,$CLJS.Pj,function(a){throw $CLJS.zj($CLJS.VH("{0}: Don''t know how to determine the type of {1}",$CLJS.G([Aga,$CLJS.aj.l($CLJS.G([a]))])),new $CLJS.k(null,1,[$CLJS.IL,a],null));});$CLJS.DL.o(null,$CLJS.QL,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.EL(a)});
var GL=function GL(a,b){return $CLJS.le(a)?$CLJS.Xf(function(d){return GL.h?GL.h(d,b):GL.call(null,d,b)},a):$CLJS.le(b)?$CLJS.Xf(function(d){return GL.h?GL.h(a,d):GL.call(null,a,d)},b):$CLJS.F.h(a,$CLJS.NL)?!0:$CLJS.PH(a,b)};$CLJS.kL($CLJS.LL,HL($CLJS.xl,"expression returning a boolean"));$CLJS.kL($CLJS.SL,HL($CLJS.Pl,"expression returning a string"));$CLJS.kL($CLJS.UL,HL($CLJS.gl,"expression returning an integer"));$CLJS.kL(yga,HL($CLJS.LK,"expression returning a non-integer real number"));
$CLJS.kL($CLJS.TL,HL($CLJS.Bl,"expression returning a number"));$CLJS.kL(zga,HL($CLJS.PI,"expression returning a date"));$CLJS.kL(Bga,HL($CLJS.YK,"expression returning a time"));$CLJS.kL(wga,HL($CLJS.TJ,"expression returning a date time"));$CLJS.kL($CLJS.KL,HL($CLJS.Il,"expression returning a date, time, or date time"));$CLJS.VL=new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.Bl,null,$CLJS.Pl,null,$CLJS.Il,null,$CLJS.xl,null],null),null);$CLJS.kL($CLJS.RL,HL($CLJS.VL,"an expression that can be compared with :\x3e or :\x3c"));
$CLJS.kL($CLJS.PL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,HL(new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.Bl,null,$CLJS.Pl,null,$CLJS.Il,null,$CLJS.xl,null],null),null),"an expression that can appear in :\x3d or :!\x3d")],null));$CLJS.kL($CLJS.ML,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,HL($CLJS.ll,"any type of expression")],null));
$CLJS.kL($CLJS.JL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kl,new $CLJS.k(null,1,[$CLJS.ok,1],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.ML],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.yt,$CLJS.Wt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OL,$CLJS.Ll],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,$CLJS.Wt],null)],null)],null)],null));