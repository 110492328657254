var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var S1,Epa,T1,U1,Fpa,Gpa,V1,W1,Hpa,Ipa,Jpa,Kpa,a2,Mpa,Npa,Opa,Ppa,d2,e2,f2,g2,i2,Rpa,j2,k2,Spa,X1,Z1;S1=function(a,b){return $CLJS.oe(a)?$CLJS.M.h(a,b):a};
Epa=function(){return function(){function a(g,l,n){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Uk.j?$CLJS.Uk.j(g,l,n):$CLJS.Uk.call(null,g,l,n),$CLJS.ln.j?$CLJS.ln.j(g,l,n):$CLJS.ln.call(null,g,l,n)],null)}function b(g,l){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Uk.h?$CLJS.Uk.h(g,l):$CLJS.Uk.call(null,g,l),$CLJS.ln.h?$CLJS.ln.h(g,l):$CLJS.ln.call(null,g,l)],null)}function c(g){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Uk.g?$CLJS.Uk.g(g):$CLJS.Uk.call(null,g),$CLJS.ln.g?$CLJS.ln.g(g):$CLJS.ln.call(null,
g)],null)}function d(){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Uk.A?$CLJS.Uk.A():$CLJS.Uk.call(null),$CLJS.ln.A?$CLJS.ln.A():$CLJS.ln.call(null)],null)}var e=null,f=function(){function g(n,q,u,v){var x=null;if(3<arguments.length){x=0;for(var y=Array(arguments.length-3);x<y.length;)y[x]=arguments[x+3],++x;x=new $CLJS.z(y,0,null)}return l.call(this,n,q,u,x)}function l(n,q,u,v){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.R.R($CLJS.Uk,n,q,u,v),$CLJS.R.R($CLJS.ln,n,q,u,v)],null)}g.v=3;g.B=function(n){var q=
$CLJS.C(n);n=$CLJS.D(n);var u=$CLJS.C(n);n=$CLJS.D(n);var v=$CLJS.C(n);n=$CLJS.zd(n);return l(q,u,v,n)};g.l=l;return g}();e=function(g,l,n,q){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,g);case 2:return b.call(this,g,l);case 3:return a.call(this,g,l,n);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.z(v,0,null)}return f.l(g,l,n,u)}throw Error("Invalid arity: "+arguments.length);};e.v=
3;e.B=f.B;e.A=d;e.g=c;e.h=b;e.j=a;e.l=f.l;return e}()};T1=function(a,b){return $CLJS.le(a)||$CLJS.me(a)?$CLJS.M.h(a,b):$CLJS.ne(a)?$CLJS.M.h($CLJS.Mg(a),b):null};U1=function(a,b){var c=$CLJS.A(b);for(a=$CLJS.A($CLJS.kg(a,b));;)if(a)c=$CLJS.D(c),a=$CLJS.D(a);else return c};Fpa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return null==e?d:$CLJS.U.j(d,f,e)},null==b?a:$CLJS.U.j(a,$CLJS.PP,b),$CLJS.wu(2,2,c))};
Gpa=function(){var a=$CLJS.Pp.h($CLJS.QZ,null),b=$CLJS.qy(a,$CLJS.Maa,function(c){return $CLJS.Po(c,$CLJS.Lg)});return function(){function c(f,g,l){return(g=$CLJS.A(b.j?b.j(f,g,l):b.call(null,f,g,l)))?new $CLJS.k(null,3,[$CLJS.$l,a,$CLJS.ol,f,$CLJS.eu,g],null):null}function d(f){return e.j(f,$CLJS.Lg,$CLJS.Lg)}var e=null;e=function(f,g,l){switch(arguments.length){case 1:return d.call(this,f);case 3:return c.call(this,f,g,l)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;return e}()};
V1=function(a){return Gpa()(a,$CLJS.Lg,$CLJS.Lg)};W1=function(a,b,c,d){d=$CLJS.m(d)?d:$CLJS.Wr($CLJS.$l.g(a));if($CLJS.m(b)){var e=S1($CLJS.gv.g(b),c);$CLJS.m(e)?(e=$CLJS.$r(e,d),a=e.h?e.h(a,d):e.call(null,a,d)):a=null;return $CLJS.m(a)?a:S1($CLJS.ev.g(b),c)}return null};
Hpa=function(a,b){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.$l),d=$CLJS.M.h(a,$CLJS.Uk);b=$CLJS.Qf(b);var e=$CLJS.M.j(b,$CLJS.eu,$CLJS.mba),f=$CLJS.M.j(b,$CLJS.hba,!0),g=$CLJS.M.h(b,$CLJS.pv),l=$CLJS.M.j(b,$CLJS.nv,$CLJS.lv),n=W1(a,$CLJS.ls.g(c),g,b);if($CLJS.m(n))return n;n=W1(a,$CLJS.tu.g(c),g,b);if($CLJS.m(n))return n;n=W1(a,e.g?e.g(d):e.call(null,d),g,b);if($CLJS.m(n))return n;n=W1(a,function(){var q=$CLJS.xp.g(c);return e.g?e.g(q):e.call(null,q)}(),g,b);if($CLJS.m(n))return n;n=W1(a,$CLJS.ls.g(c),
l,b);if($CLJS.m(n))return n;n=W1(a,$CLJS.tu.g(c),l,b);if($CLJS.m(n))return n;d=W1(a,e.g?e.g(d):e.call(null,d),l,b);if($CLJS.m(d))return d;d=W1(a,function(){var q=$CLJS.xp.g(c);return e.g?e.g(q):e.call(null,q)}(),l,b);if($CLJS.m(d))return d;g=$CLJS.m(f)?W1(a,e.g?e.g($CLJS.jv):e.call(null,$CLJS.jv),g,b):f;return $CLJS.m(g)?g:$CLJS.m(f)?W1(a,e.g?e.g($CLJS.jv):e.call(null,$CLJS.jv),l,b):f};
Ipa=function(a,b,c){a=$CLJS.T;var d=$CLJS.oh.h;var e=$CLJS.ln.g(b);var f=$CLJS.Qf(b);var g=$CLJS.M.h(f,$CLJS.$l),l=$CLJS.Qf(c);f=$CLJS.M.h(l,$CLJS.pv);l=$CLJS.M.j(l,$CLJS.nv,$CLJS.lv);g=$CLJS.ls.g(g);f=S1($CLJS.mv.g(g),f);f=$CLJS.m(f)?f:S1($CLJS.mv.g(g),l);d=d.call($CLJS.oh,e,f);return new $CLJS.S(null,2,5,a,[d,Hpa(b,c)],null)};
Jpa=function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.ol);a=$CLJS.M.h(b,$CLJS.eu);var d=$CLJS.Qf(null),e=$CLJS.M.j(d,$CLJS.iba,$CLJS.wk),f=$CLJS.M.j(d,$CLJS.kba,Ipa);return $CLJS.m(a)?$CLJS.Sb(function(g,l){var n=f.j?f.j(b,l,d):f.call(null,b,l,d),q=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);l=$CLJS.U.j(l,$CLJS.wk,n);l=e.g?e.g(l):e.call(null,l);return X1(g,c,q,l)},null,a):null};Kpa=function(a){return $CLJS.qg.h($CLJS.vZ(a),$CLJS.wZ(a))};
$CLJS.Y1=function(a,b){return $CLJS.Qu($CLJS.Y_(a)?a:new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ol,new $CLJS.k(null,2,[$CLJS.JG,$CLJS.p.g($CLJS.qL()),$CLJS.jk,$CLJS.EL(a)],null),a],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.OL],null),b)};$CLJS.$1=function(){return $CLJS.Im.h(Z1,$CLJS.l_($CLJS.G([$CLJS.m_,$CLJS.lG,$CLJS.n_,function(a,b){return Z1.g([$CLJS.p.g(a),"_",$CLJS.p.g(b)].join(""))}])))};
a2=function(a,b,c){var d=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.$t,null,$CLJS.pn,null],null),null),e=$CLJS.m(d.g?d.g(b):d.call(null,b))?$CLJS.Mu(2,c):$CLJS.Mu(1,c);b=$CLJS.m(d.g?d.g(b):d.call(null,b))?U1(2,c):U1(1,c);var f=$CLJS.J(b,0,null);return $CLJS.A(e)?$CLJS.K0.j(a,e,function(g){var l=$CLJS.U.j(g,f,null);return $CLJS.qe(g)?$CLJS.oh.j($CLJS.Lg,$CLJS.Lm.g($CLJS.Cb),l):$CLJS.oe(g)?$CLJS.bG($CLJS.Hb,l):l}):$CLJS.Am.h(a,f)};
Mpa=function(a){return $CLJS.Sb(function(b,c){var d=$CLJS.J(c,0,null);$CLJS.J(c,1,null);return a2(b,Lpa,d)},a,Kpa(a))};
Npa=function(a){for(var b=0;;){var c=$CLJS.Td($CLJS.hZ.g(a),b);a:{var d=c;for(var e=$CLJS.Lg;;){var f=$CLJS.C($CLJS.rg.h(Epa(),$CLJS.ug($CLJS.Im.j(b2,$CLJS.C,$CLJS.ln),$CLJS.eu.g(V1(d)))));if($CLJS.m(f)){var g=f;f=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);var l=a2(d,f,g),n=$CLJS.ZF($CLJS.ZD);if($CLJS.m($CLJS.fG("metabase.lib.convert",n))){var q=$CLJS.PG.l($CLJS.G([$CLJS.UE,$CLJS.aj.l($CLJS.G([g]))])),u=$CLJS.PG,v=u.l,x=$CLJS.aj,y=x.l;var B=f;B=$CLJS.m(B)?B:Jpa(V1(d));$CLJS.gG("metabase.lib.convert",n,
$CLJS.W_("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([q,v.call(u,$CLJS.G([$CLJS.UE,y.call(x,$CLJS.G([B]))])),$CLJS.PG.l($CLJS.G([$CLJS.JE,$CLJS.X_($CLJS.C($CLJS.Cz(d,l)))]))])),null)}if($CLJS.F.h(l,d))break a;d=l;e=$CLJS.kf.h(e,new $CLJS.S(null,2,5,$CLJS.T,[f,g],null))}else break a}}d=Mpa(d);if($CLJS.F.h(c,d)){if($CLJS.F.h(b,$CLJS.E($CLJS.hZ.g(a))-1))return a;b+=1}else a=$CLJS.Nm.R(a,$CLJS.hZ,$CLJS.U,b,d)}};
Opa=function(a){var b=$CLJS.$1();return $CLJS.Mm.h(function(c){return $CLJS.F.h($CLJS.yP.g(c),"__join")?$CLJS.Nm.j(c,$CLJS.yP,b):c},a)};Ppa=function(a){return"string"===typeof $CLJS.aQ.g(a)?$CLJS.Am.h($CLJS.U.j(a,$CLJS.xZ,$CLJS.d0($CLJS.aQ.g(a))),$CLJS.aQ):a};d2=function(a,b){var c=$CLJS.M.j(a,b,c2);if($CLJS.F.h(c,c2))throw $CLJS.zj(["Unable to find ",$CLJS.aj.l($CLJS.G([b]))," in map."].join(""),new $CLJS.k(null,2,[Qpa,a,$CLJS.$u,b],null));return c};
e2=function(){return $CLJS.Lm.g(function(a){var b=$CLJS.J(a,0,null);$CLJS.J(a,1,null);return $CLJS.xf(b)&&$CLJS.F.h($CLJS.qf(b),"lib")})};f2=function(a){return $CLJS.oh.j($CLJS.P,e2(),a)};g2=function(a){return $CLJS.Rf($CLJS.oh.j($CLJS.P,$CLJS.Im.h(e2(),$CLJS.Lm.g(function(b){var c=$CLJS.J(b,0,null);$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.jk)})),a))};
i2=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d=$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.g($CLJS.h2),c);a=$CLJS.F.h(a,$CLJS.$I)&&$CLJS.D(c)?$CLJS.kf.h($CLJS.ie(d),new $CLJS.k(null,1,[$CLJS.Pj,$CLJS.he(d)],null)):d;b=$CLJS.Rf(g2(b));return $CLJS.m(b)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qO,a,b],null):a};Rpa=function(a){return $CLJS.oh.j($CLJS.Lg,$CLJS.Im.h($CLJS.rg.g(function(b){return $CLJS.dG(b,$CLJS.UG)}),$CLJS.rg.g($CLJS.h2)),$CLJS.b0.g(a))};
j2=function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.hZ);a=$CLJS.C($CLJS.Sb(function(b,c){var d=$CLJS.J(b,0,null),e=$CLJS.J(b,1,null);b=$CLJS.T;var f=$CLJS.h2.g(c);d=$CLJS.m(d)?$CLJS.U.j(f,$CLJS.nR,d):f;d=$CLJS.m(e)?$CLJS.U.j(d,$CLJS.QP,Rpa(e)):d;return new $CLJS.S(null,2,5,b,[d,$CLJS.oZ.g(c)],null)},null,a));return $CLJS.m($CLJS.WQ.g(a))?$CLJS.SN(a,new $CLJS.k(null,1,[$CLJS.WQ,$CLJS.xO],null)):a};
k2=function(a,b,c){var d=$CLJS.F.h($CLJS.E($CLJS.M.h(a,b)),1)?$CLJS.u0.j(a,b,$CLJS.Im.h($CLJS.h2,$CLJS.C)):a;a=1<$CLJS.E($CLJS.M.h(a,b))?$CLJS.u0.j(d,b,function(e){return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Lj],null),$CLJS.rg.g($CLJS.h2),e)}):d;return $CLJS.SN(a,$CLJS.Pf([b,c]))};Spa=function(a){var b=$CLJS.xZ.g(a);return $CLJS.m(b)?$CLJS.U.j($CLJS.Am.h(a,$CLJS.xZ),$CLJS.aQ,["card__",$CLJS.p.g(b)].join("")):a};
X1=function X1(a,b,c,d){var f=$CLJS.A(c);c=$CLJS.C(f);var g=$CLJS.D(f),l=T1(b,c);f=$CLJS.m(a)?a:$CLJS.ne(b)?$CLJS.Lg:$CLJS.pe(b)?$CLJS.P:$CLJS.ae(b);return $CLJS.m($CLJS.m(c)?$CLJS.kv.g($CLJS.fe(f)):c)?a:$CLJS.m(c)?(b=T1(f,c),d=X1.D?X1.D(b,l,g,d):X1.call(null,b,l,g,d),$CLJS.Be(c)&&$CLJS.ne(f)&&c>$CLJS.E(f)&&(b=$CLJS.ng(c-$CLJS.E(f),null),b=$CLJS.qg.h(f,b),f=null==f||$CLJS.we(f)?b:$CLJS.oh.h($CLJS.ae(f),b)),null==f||$CLJS.me(f)?$CLJS.U.j(f,c,d):$CLJS.le(f)?$CLJS.kf.h(f,d):$CLJS.R.h($CLJS.X,$CLJS.U.j($CLJS.Mg(f),
c,d))):$CLJS.oe(a)?(c=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.jba],null),X1.D?X1.D(f,b,c,d):X1.call(null,f,b,c,d)):$CLJS.m($CLJS.kv.g($CLJS.fe(f)))?$CLJS.kf.h(f,d):$CLJS.qe($CLJS.Rf(f))?f:$CLJS.K(new $CLJS.S(null,1,5,$CLJS.T,[d],null),new $CLJS.k(null,1,[$CLJS.kv,!0],null))};Z1=function Z1(a){switch(arguments.length){case 1:return Z1.g(arguments[0]);case 2:return Z1.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
Z1.g=function(a){return Z1.h(a,60)};Z1.h=function(a,b){if((new TextEncoder).encode(a).length<=b)return a;var c;a:for(c=($CLJS.SZ.str(a,0)>>>0).toString(16);;)if(8>$CLJS.E(c))c=["0",$CLJS.p.g(c)].join("");else break a;b=new Uint8Array(b-9);b=(new TextEncoder).encodeInto(a,b);return[a.substring(0,b.read),"_",$CLJS.p.g(c)].join("")};Z1.v=2;
var c2=new $CLJS.N("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550),Lpa=new $CLJS.N("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475),l2=new $CLJS.N("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672),m2=new $CLJS.N("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782),Qpa=new $CLJS.N(null,"m","m",1632677161),n2=new $CLJS.N("metabase.lib.convert","aggregation",
"metabase.lib.convert/aggregation",108290790);var o2,p2,b2,Tpa,Upa,Vpa,Wpa,Xpa,Ypa,Zpa,$pa,aqa,bqa;o2=$CLJS.P;p2=$CLJS.P;b2=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.NZ,null,$CLJS.zO,null,$CLJS.XO,null,$CLJS.RQ,null,$CLJS.IP,null,$CLJS.aS,null,$CLJS.PP,null],null),null);Tpa=$CLJS.cj.g($CLJS.P);Upa=$CLJS.cj.g($CLJS.P);Vpa=$CLJS.cj.g($CLJS.P);Wpa=$CLJS.cj.g($CLJS.P);Xpa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Ok,$CLJS.EH],null),$CLJS.Ok,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
$CLJS.q2=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3epMBQL"),$CLJS.DH,$CLJS.Pj,Xpa,Tpa,Upa,Vpa,Wpa);$CLJS.q2.o(null,$CLJS.Pj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){var b=$CLJS.he(a);var c=$CLJS.oe(b);a=c?$CLJS.ie(a):a;var d=$CLJS.A(a);a=$CLJS.C(d);d=$CLJS.D(d);b=$CLJS.gW($CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[a,c?b:$CLJS.P],null),$CLJS.rg.g($CLJS.q2),d))}else b=a;return b});$CLJS.q2.o(null,$CLJS.MZ,function(a){return a});
$CLJS.q2.o(null,$CLJS.CZ,function(a){var b=$CLJS.q2.g($CLJS.PP.g(a)),c=$CLJS.Rf($CLJS.Mm.h(function(q){var u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);return $CLJS.Y1($CLJS.q2.g(q),u)},$CLJS.RQ.g(a))),d=$CLJS.oh.j($CLJS.P,$CLJS.Go(function(q,u){$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);u=$CLJS.Qf(u);u=$CLJS.M.h(u,$CLJS.JG);return new $CLJS.S(null,2,5,$CLJS.T,[q,u],null)}),b),e=$CLJS.oh.j($CLJS.P,$CLJS.Go(function(q,u){$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);u=$CLJS.Qf(u);u=$CLJS.M.h(u,$CLJS.JG);return new $CLJS.S(null,
2,5,$CLJS.T,[u,q],null)}),b),f=p2,g=o2;p2=d;o2=e;try{var l=Fpa(Ppa(a),b,$CLJS.G([$CLJS.RQ,c])),n=$CLJS.Sb(function(q,u){return $CLJS.Gb($CLJS.M.h(q,u))?q:$CLJS.Nm.j(q,u,$CLJS.q2)},l,$CLJS.Bm.l(b2,$CLJS.PP,$CLJS.G([$CLJS.RQ])));return $CLJS.m($CLJS.XO.g(n))?$CLJS.Nm.j(n,$CLJS.XO,Opa):n}finally{o2=g,p2=f}});
$CLJS.q2.o(null,$CLJS.cZ,function(a){a=$CLJS.Nm.j($CLJS.Nm.j(a,$CLJS.kZ,$CLJS.q2),$CLJS.hZ,$CLJS.q2);var b=$CLJS.m($CLJS.zO.g(a))?$CLJS.Nm.j(a,$CLJS.zO,function(c){return $CLJS.xe(c)?$CLJS.Mm.h($CLJS.q2,c):$CLJS.Oi.g(c)}):a;return $CLJS.Gb($CLJS.yP.g(a))?$CLJS.U.j(b,$CLJS.yP,"__join"):b});$CLJS.q2.o(null,$CLJS.yG,function(a){return $CLJS.Mm.h($CLJS.q2,a)});
$CLJS.q2.o(null,$CLJS.AG,function(a){return $CLJS.m($CLJS.Uk.g(a))?Npa($CLJS.Nm.j($CLJS.c0(a),$CLJS.hZ,function(b){return $CLJS.Mm.h($CLJS.q2,b)})):$CLJS.Lu(a,$CLJS.q2)});$CLJS.q2.o(null,$CLJS.GQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[a,b],null):new $CLJS.S(null,2,5,$CLJS.T,[b,a],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.gW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,a,b],null))});
$CLJS.q2.o(null,$CLJS.ol,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.SN(a,new $CLJS.k(null,3,[$CLJS.uJ,$CLJS.EG,$CLJS.EP,$CLJS.Hl,$CLJS.iS,$CLJS.xL],null));var c=$CLJS.U.j;var d=$CLJS.jk.g(a);$CLJS.m(d)||(d=$CLJS.EG.g(a),d=$CLJS.m(d)?d:$CLJS.EL(b));a=c.call($CLJS.U,a,$CLJS.jk,d);return $CLJS.gW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ol,a,b],null))});
$CLJS.q2.o(null,$CLJS.$I,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.Pj.g(c);b=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$I,$CLJS.Am.h(c,$CLJS.Pj),$CLJS.Mm.h($CLJS.q2,b)],null);b=$CLJS.gW(b);return null!=a?$CLJS.kf.h(b,$CLJS.q2.g(a)):b});$CLJS.q2.o(null,$CLJS.BG,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.gW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,c],null))});
$CLJS.q2.o(null,$CLJS.PP,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.gW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,d2(p2,c)],null))});$CLJS.q2.o(null,$CLJS.qO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=$CLJS.q2.g(b);var c=$CLJS.A(b);b=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);d=$CLJS.D(d);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.cn.l($CLJS.G([c,a]))],null),d)});Ypa=$CLJS.cj.g($CLJS.P);Zpa=$CLJS.cj.g($CLJS.P);$pa=$CLJS.cj.g($CLJS.P);
aqa=$CLJS.cj.g($CLJS.P);bqa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Ok,$CLJS.EH],null),$CLJS.Ok,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));$CLJS.h2=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.DH,$CLJS.Pj,bqa,Ypa,Zpa,$pa,aqa);
$CLJS.h2.o(null,$CLJS.Pj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){a=$CLJS.A(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);if($CLJS.oe(a)){c=$CLJS.oh.h;b=new $CLJS.S(null,1,5,$CLJS.T,[b],null);var e=$CLJS.qg.h;d=$CLJS.rg.h($CLJS.h2,d);a=g2(a);a=c.call($CLJS.oh,b,e.call($CLJS.qg,d,$CLJS.m(a)?new $CLJS.S(null,1,5,$CLJS.T,[a],null):null))}else a=$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[b],null),$CLJS.rg.h($CLJS.h2,$CLJS.nf(a,d)))}else a=$CLJS.oe(a)?$CLJS.Lu(f2(a),$CLJS.h2):
a;return a});for(var r2=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[n2,m2],null)),s2=null,t2=0,u2=0;;)if(u2<t2){var cqa=s2.$(null,u2);$CLJS.oV(cqa,l2);u2+=1}else{var v2=$CLJS.A(r2);if(v2){var w2=v2;if($CLJS.re(w2)){var x2=$CLJS.$c(w2),dqa=$CLJS.ad(w2),eqa=x2,fqa=$CLJS.E(x2);r2=dqa;s2=eqa;t2=fqa}else{var gqa=$CLJS.C(w2);$CLJS.oV(gqa,l2);r2=$CLJS.D(w2);s2=null;t2=0}u2=0}else break}
for(var y2=$CLJS.A(new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.mD,$CLJS.DJ,$CLJS.fK,$CLJS.NI,$CLJS.Vl,$CLJS.vK,$CLJS.ok,$CLJS.nJ,$CLJS.ZJ,$CLJS.AK,$CLJS.tK,$CLJS.OK],null)),z2=null,A2=0,B2=0;;)if(B2<A2){var hqa=z2.$(null,B2);$CLJS.oV(hqa,n2);B2+=1}else{var C2=$CLJS.A(y2);if(C2){var D2=C2;if($CLJS.re(D2)){var E2=$CLJS.$c(D2),iqa=$CLJS.ad(D2),jqa=E2,kqa=$CLJS.E(E2);y2=iqa;z2=jqa;A2=kqa}else{var lqa=$CLJS.C(D2);$CLJS.oV(lqa,n2);y2=$CLJS.D(D2);z2=null;A2=0}B2=0}else break}
for(var F2=$CLJS.A($CLJS.Hg([$CLJS.im,$CLJS.yu,$CLJS.jm,$CLJS.QK,$CLJS.$I,$CLJS.RJ,$CLJS.uI,$CLJS.SK,$CLJS.$H,$CLJS.pJ,$CLJS.kI,$CLJS.HJ,$CLJS.WJ,$CLJS.nI,$CLJS.jJ,$CLJS.sJ,$CLJS.$D,$CLJS.GI,$CLJS.EJ,$CLJS.vI,$CLJS.dK,$CLJS.FI,$CLJS.ZH,$CLJS.bK,$CLJS.xK,$CLJS.hK,$CLJS.EI,$CLJS.TK,$CLJS.eK,$CLJS.MK,$CLJS.pI,$CLJS.bI,$CLJS.WK,$CLJS.ZW,$CLJS.PJ,$CLJS.kD,$CLJS.CK,$CLJS.HK,$CLJS.ZK,$CLJS.Su,$CLJS.av],!0)),G2=null,H2=0,I2=0;;)if(I2<H2){var mqa=G2.$(null,I2);$CLJS.oV(mqa,m2);I2+=1}else{var J2=$CLJS.A(F2);
if(J2){var K2=J2;if($CLJS.re(K2)){var L2=$CLJS.$c(K2),nqa=$CLJS.ad(K2),oqa=L2,pqa=$CLJS.E(L2);F2=nqa;G2=oqa;H2=pqa}else{var qqa=$CLJS.C(K2);$CLJS.oV(qqa,m2);F2=$CLJS.D(K2);G2=null;H2=0}I2=0}else break}$CLJS.h2.o(null,l2,function(a){return i2(a)});$CLJS.h2.o(null,$CLJS.AG,function(a){return $CLJS.oh.j($CLJS.P,$CLJS.Im.h(e2(),$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.h2.g(b)],null)})),a)});
$CLJS.h2.o(null,$CLJS.PP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);return $CLJS.oe(b)?(a=g2(b),c=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PP,d2(o2,c)],null),$CLJS.m(a)?$CLJS.kf.h(c,a):c):a});$CLJS.h2.o(null,$CLJS.yG,function(a){return $CLJS.Mm.h($CLJS.h2,a)});
$CLJS.h2.o(null,$CLJS.GQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=null==b||$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[b,a],null):new $CLJS.S(null,2,5,$CLJS.T,[a,b],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,$CLJS.h2.g(a),g2(b)],null)});
$CLJS.h2.o(null,$CLJS.ol,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=g2($CLJS.SN(b,new $CLJS.k(null,3,[$CLJS.EG,$CLJS.uJ,$CLJS.Hl,$CLJS.EP,$CLJS.xL,$CLJS.iS],null)));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ol,a,b],null)});
$CLJS.h2.o(null,$CLJS.cZ,function(a){var b=f2(a);a=0==$CLJS.yP.g(a).lastIndexOf("__join",0)?$CLJS.Am.h(b,$CLJS.yP):b;return $CLJS.cn.l($CLJS.G([$CLJS.Lu($CLJS.Am.l(a,$CLJS.hZ,$CLJS.G([$CLJS.kZ])),$CLJS.h2),k2($CLJS.li(a,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.kZ],null)),$CLJS.kZ,$CLJS.cS),j2(a)]))});
$CLJS.h2.o(null,$CLJS.CZ,function(a){var b=o2;o2=$CLJS.oh.j($CLJS.P,$CLJS.Go(function(c,d){$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.JG);return new $CLJS.S(null,2,5,$CLJS.T,[d,c],null)}),$CLJS.PP.g(a));try{return $CLJS.Sb(function(c,d){return $CLJS.u0.j(c,d,$CLJS.h2)},k2($CLJS.u0.j($CLJS.u0.j(Spa(f2(a)),$CLJS.PP,function(c){return $CLJS.Mm.h(i2,c)}),$CLJS.RQ,function(c){return $CLJS.oh.h($CLJS.P,function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=
$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u),x=$CLJS.h2.g(v);v=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Z_(v),$CLJS.F.h($CLJS.ol,$CLJS.C(x))?$CLJS.Zd(x):x],null);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);l=$CLJS.h2.g(q);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Z_(q),$CLJS.F.h($CLJS.ol,$CLJS.C(l))?$CLJS.Zd(l):l],null),f($CLJS.zd(g)))}return null}},
null,null)}(c)}())}),$CLJS.NZ,$CLJS.LO),$CLJS.Bm.l(b2,$CLJS.PP,$CLJS.G([$CLJS.NZ,$CLJS.RQ])))}finally{o2=b}});$CLJS.h2.o(null,$CLJS.IZ,function(a){return $CLJS.Lu(f2(a),$CLJS.h2)});
$CLJS.h2.o(null,$CLJS.MZ,function(a){var b=f2(a),c=$CLJS.QO.g(b),d=j2(b),e=$CLJS.F.h($CLJS.wz.g($CLJS.$d($CLJS.hZ.g(a))),$CLJS.IZ)?$CLJS.WQ:$CLJS.xO;a=$CLJS.cn.l;b=$CLJS.Lu($CLJS.Am.l(b,$CLJS.hZ,$CLJS.G([$CLJS.QO])),$CLJS.h2);d=$CLJS.Pf([$CLJS.Uk,e,e,d]);c=$CLJS.A(c)?$CLJS.U.j(d,$CLJS.QO,c):d;return a.call($CLJS.cn,$CLJS.G([b,c]))});